<template>
  <div
    class="v-product-variant-box"
    :class="{
      'is-selected': isSelected,
      'show-image': showImage,
      'horizontal-variant-box-on-mobile': horizontalVariantBoxOnMobile,
      disabled: !isAvailable,
      [`theme-${theme}`]: true,
    }"
  >
    <FlyImage v-if="showImage" :attachment-id="previewImageId" preset="vue-variant-preview-image" :key="previewImageId || variantValue" class="round" aspect-ratio="1/1" />
    <div v-else class="v-variant-circle">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
        <circle cx="12" cy="12.5" r="11" stroke="#E3DED8" stroke-width="2" />
      </svg>
    </div>
    <div class="v-variant-content">
      <span class="v-variant-name font--middle">{{ variantValue ?? labels.no_variant }}</span>
      <span class="font--small" v-if="variantDescription">{{ variantDescription }}</span>
      <span v-if="price" class="v-price-difference font--small">+ {{ configurator.totalCurrencyCode }} {{ price.toFixed(2) }} </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import FlyImage from '@/js/vue/components/FlyImage.vue';
import { useConfiguratorStore } from '@/js/vue/shopify/stores/configurator';
import { computed, PropType } from 'vue';
import { Product } from '@/js/vue/shopify/queries';
import { VariantName } from '@/js/vue/shopify/types';
import { useSettingsStore } from '@/js/vue/shopify/stores/settings';

const labels = computed(() => settings.labels.configurator);
const store = useConfiguratorStore();
const configurator = useConfiguratorStore();
const settings = useSettingsStore();

const props = defineProps({
  product: {
    type: Object as PropType<Product>,
    required: true,
  },
  variantName: {
    type: String as PropType<VariantName>,
    required: false,
  },
  variantValue: {
    type: String,
    required: false,
  },
  showPreviewImageSkeleton: {
    type: Boolean,
    default: () => false,
  },
  productAusfuehrung: {
    type: String,
  },
  isSelected: {
    type: Boolean,
    default: () => false,
  },
  isAvailable: {
    type: Boolean,
    default: () => true,
  },
  horizontalVariantBoxOnMobile: {
    type: Boolean,
    default: () => false,
  },
  theme: {
    type: String as PropType<'dark' | 'light'>,
    default: () => 'dark',
  },
  price: {
    type: Number,
  },
});

const previewImageId = computed(() =>
  props.variantName && props.variantValue
    ? settings.findVariantValueSetting(props.product, props.variantName, props.variantValue, 'image_preview', props.productAusfuehrung)
    : undefined
);
const variantDescription = computed(() =>
  props.variantName && props.variantValue
    ? settings.findVariantValueSetting(props.product, props.variantName, props.variantValue, 'description', props.productAusfuehrung)
    : undefined
);
const showImage = computed(() => previewImageId.value || props.showPreviewImageSkeleton);
</script>

<style lang="scss" scoped>
.v-product-variant-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
  align-items: center;
  background: $wbrown;
  color: $white;
  border: 2px solid $white;
  border-radius: 30px;
  padding: 15px;
  cursor: pointer;
  transition: box-shadow $transition-duration $cubic-bezier, border $transition-duration $cubic-bezier;
  max-width: 250px;
  flex-shrink: 0;
  @include media(XXL) {
    padding: 12px 10px;
  }
  @include media(M) {
    padding: 10px;
    gap: 10px;
    max-width: none;
  }

  .v-variant-circle {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media(M) {
      width: 20px;
      height: 20px;
    }
  }
  .v-fly-image {
    width: 80px;
    height: 80px;
    @include media(L) {
      width: 70px;
      height: 70px;
    }
  }
  .v-variant-content {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .v-variant-name {
      font-weight: 500;
      @include media(M) {
        font-size: 14px;
      }
    }
    .font--small {
      line-height: 1.21;
      @include media(M) {
        font-size: 12px;
      }
    }

    .v-price-difference {
      color: $primary;
      margin-top: 10px;
      @include media(M) {
        margin-top: 5px;
      }
    }
  }

  &.show-image {
    flex-direction: column;
    border-radius: 15px;
    width: 100%;

    &.horizontal-variant-box-on-mobile {
      @include media(M) {
        flex-direction: row;
        justify-content: flex-start;
        gap: 30px;

        .v-variant-content {
          align-items: flex-start;
          text-align: left;
        }
      }
    }

    .v-variant-content {
      align-items: center;
      text-align: center;
    }
  }

  &.theme-light {
    background: $white;
    color: $wbrown;
    box-shadow: none;

    &:hover {
      border-color: $blue-light;
    }
  }

  &.is-selected {
    background: $white;
    color: $wbrown;
    border-color: $blue-light;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
}
</style>
