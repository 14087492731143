<template>
  <div class="v-product-header-feature">
    <FlyImage :attachment-id="featureImage" aspect-ratio="1/1" class="round" preset="product-header-feature" />
    <div class="font--middle v-product-header-feature-text">
      <strong class="v-feature-title">{{ title }}</strong>
      <div class="v-feature-description">{{ description }}</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue';
import { ProductImageName, useSettingsStore } from '@/js/vue/shopify/stores/settings';
import { useConfiguratorStore } from '@/js/vue/shopify/stores/configurator';
import { VARIANT_NAMES } from '@/js/vue/shopify/keys';
import FlyImage from '@/js/vue/components/FlyImage.vue';

const props = defineProps({
  title: {
    type: String,
  },
  description: {
    type: String,
  },
  productImageName: {
    type: String as PropType<ProductImageName>,
  },
});

const configurator = useConfiguratorStore();
const settings = useSettingsStore();

const featureImage = computed<number | undefined>(() =>
  settings.findProductImageForAusfuehrung(configurator.products.main.product, configurator.selectedProductOptions.main[VARIANT_NAMES['Ausfuehrung']], props.productImageName)
);
</script>

<style lang="scss" scoped>
.v-product-header-feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $wbrown;
  text-align: center;
  max-width: 200px;
  @include media(XL1200) {
    max-width: 170px;
  }
  @include media(L) {
    flex-direction: row;
    max-width: none;
    gap: 30px;
  }

  .v-fly-image {
    width: 150px;
    height: 150px;
    margin-bottom: 25px;
    flex-shrink: 0;
    @include media(XL1200) {
      width: 120px;
      height: 120px;
      margin-bottom: 20px;
    }
    @include media(L) {
      margin-bottom: 0;
    }
  }
  .v-product-header-feature-text {
    display: flex;
    flex-direction: column;
    gap: 6px;

    > * {
      @include media(L) {
        text-align: left;
      }
    }
    strong {
      font-weight: 500;
    }
    .v-feature-description {
      font-weight: 300;
    }
  }
}
</style>
