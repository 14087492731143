import $ from 'jquery';
import 'lazysizes';
import baguetteBox from 'baguettebox.js';
import qs from 'qs';

$(() => {
  window.greaterLoveForm();
  lazySizes.init();

  // Tracking
  var checkExist = setInterval(function () {
    if (typeof ga !== 'undefined') {
      clearInterval(checkExist);

      // Analyitcs Tests
      ga('create', 'UA-192006054-1', 'auto');
      $('.product-info__wood__item').click(function () {
        ga('send', {
          hitType: 'event',
          eventCategory: 'Configurator-Interaction',
          eventAction: 'Choose Wood Type',
          eventLabel: 'Chooses a Wood-Type',
        });
      });
      $('.hero-block__size__inner').click(function () {
        ga('send', {
          hitType: 'event',
          eventCategory: 'Configurator-Interaction',
          eventAction: 'Choose Size',
          eventLabel: 'Chooses a Size',
        });
      });

      $('#configure-table-button').click(function () {
        ga('send', {
          hitType: 'event',
          eventCategory: 'Enhanced Ecommerce',
          eventAction: 'Tisch konfigurieren Button im Header',
          eventLabel: 'Klick auf den Button',
        });
      });
      $('.wly-accordeon__item').click(function () {
        var textString = $(this).find('.wly-accordeon__title span').text();
        ga('send', {
          hitType: 'event',
          eventCategory: 'Site Interaction',
          eventAction: 'FAQ aufgeklappt',
          eventLabel: textString,
        });
      });

      console.log('GA is loaded');
    }
  }, 500); // check every 500ms

  // Get Product and Slider classes
  $('.product-info').closest('.et_pb_section').addClass('product--wrapper');
  $('.review-slider').closest('.et_pb_section').addClass('slider--wrapper');

  // Show Configurator Header
  $(window).scroll(function () {
    var fixedLogo = $('.product__header');
    var toCross = $('.product-info');

    if ($(toCross).length) {
      var addClass = false;
      $(toCross).each(function () {
        var fixed_height = $(fixedLogo).height();
        var fixed_position = $(fixedLogo).offset().top;

        var toCross_position = $(this).offset().top;
        var toCross_height = $(this).height();

        if (fixed_position + fixed_height / 2 < toCross_position) {
        } else if (fixed_position + fixed_height / 2 > toCross_position + toCross_height) {
        } else {
          addClass = true;
          fixedLogo.addClass('active');
        }
      });

      if (addClass == true) {
        fixedLogo.addClass('active');
      } else {
        fixedLogo.removeClass('active');
      }
    }
  });

  // Scroll to
  $(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();

    $('html, body').animate(
      {
        scrollTop: $($.attr(this, 'href')).offset().top - 91,
      },
      1000
    );
  });

  // Gallery Function
  var destinyElement = $('.product-info__image__space-holder a');

  $('.product-info__image__table img').click(function () {
    var imgSrc = '';
    imgSrc = $(this).attr('src');
    $(destinyElement).attr('href', imgSrc);

    $(destinyElement).children().remove();
    $(this).clone().appendTo(destinyElement);

    $(this).closest('.product-info__image__thumb').addClass('active');
    $(this).closest('.product-info__image__thumb').siblings().removeClass('active');

    baguetteBox.run('.product-info__image--gal', {
      noScrollbars: true,
      async: true,
      titleTag: true,
    });
  });

  $('.product-info__color__item').click(function () {
    $(this).addClass('active');
    $(this).find('.select--radio').addClass('active');
    $(this).siblings().removeClass('active');
    $(this).siblings().find('.select--radio').removeClass('active');
  });

  // Check if URL has keyword in it
  let queryString = qs.parse(window.location.search.substring(1));
  var param = queryString.holzart;
  var elementId = 0;

  if (param === 'eiche') {
    elementId = 0;
  } else if (param === 'nussbaum') {
    elementId = 1;
  } else if (param === 'esche') {
    elementId = 2;
  } else if (param === 'kernbuche') {
    elementId = 3;
  }
  imageSwitcher(elementId);

  // Konfigurator Image Switcher
  $('.product-info__wood__item').click(function () {
    elementId = $(this).index();
    imageSwitcher(elementId);
  });

  function imageSwitcher(a) {
    var elementId = a;

    var elementToShow = $('.product-info__image__table').eq(elementId);
    elementToShow.css('display', 'flex').siblings().hide();

    var elementToClone = $(elementToShow).children().first();
    $(destinyElement).children().remove();
    $(elementToClone).clone().appendTo(destinyElement);

    $(elementToClone).addClass('active');
    $(elementToClone).siblings().removeClass('active');

    var imgSrc = '';
    imgSrc = $(elementToClone).attr('src');
    $(destinyElement).attr('href', imgSrc);

    baguetteBox.run('.product-info__image--gal', {
      noScrollbars: true,
      async: true,
      titleTag: true,
    });
  }

  // Akkordeon
  $('.wly-accordeon__title').click(function () {
    $(this).parent().toggleClass('active');
    $(this).parent().siblings('.wly-accordeon__item').removeClass('active');

    $('.wly-accordeon__item').each(function () {
      if ($(this).hasClass('active')) {
        $(this).children('.wly-accordeon__content').slideDown();
      } else {
        $(this).removeClass('active');
        $(this).children('.wly-accordeon__content').slideUp();
      }
    });
  });

  // Navigation Touch Geräte
  $('ul.navbar-nav > li.parent > a').on('touchstart', function (e) {
    if (!$(this).parent().hasClass('hovered')) {
      e.preventDefault();
      $(this).parent().toggleClass('hovered');
      $(this).parent().siblings().removeClass('hovered');
    }
  });

  // Video Shower Hero Header

  $('.header-hero__play').click(function () {
    $(this).closest('.container').addClass('youtube--open');
    $('body').addClass('background--darken');
  });
  $('.darkener--closer, .darkener').click(function () {
    $('.wly-youtube-video').closest('.container').removeClass('youtube--open');
    $('body').removeClass('background--darken');
    $('.wly-youtube-video__player-video iframe').each(function () {
      this.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
    });
  });

  // Video Shower Alt Headeer
  $('.header-alt__play').click(function () {
    $(this).closest('.et_pb_section').addClass('youtube--open');
    $('body').addClass('background--darken');
  });
  $('.darkener--closer, .darkener').click(function () {
    $('.wly-youtube-video').closest('.et_pb_section').removeClass('youtube--open');
    $('body').removeClass('background--darken');
    $('.wly-youtube-video__player-video iframe').each(function () {
      this.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
    });
  });
});
