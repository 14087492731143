import { RunThemeExtensions } from 'wly-theme-extensions';
import { createPinia } from 'pinia';
import { provide } from 'vue';
import { DefaultApolloClient } from '@vue/apollo-composable';
import { apolloClient } from '@/js/vue/shopify/apollo';

import YoutubePlayer from '@/js/vue/components/YoutubePlayer.vue';
import Configurator from '@/js/vue/components/Modules/Configurator.vue';
import CartButton from '@/js/vue/components/Configurator/CartButton.vue';
import ClickOutside from '@/js/vue/directives/ClickOutside';
import ProductHeader from '@/js/vue/components/Modules/ProductHeader.vue';
import ProductHeaderFeature from '@/js/vue/components/Configurator/ProductHeaderFeature.vue';
import ProductSelection from '@/js/vue/components/Modules/ProductSelection.vue';
import SideCart from '@/js/vue/components/Configurator/SideCart.vue';

const pinia = createPinia();

RunThemeExtensions({
  vue: {
    components: {
      'wly-youtube': YoutubePlayer,
      'wly-configurator': Configurator,
      'wly-cart': SideCart,
      'wly-cart-button': CartButton,
      'wly-product-header': ProductHeader,
      'wly-product-header-feature': ProductHeaderFeature,
      'wly-product-selection': ProductSelection,
    },
    onBootstrap: (app) => {
      app.use(pinia);
      app.directive(ClickOutside);
    },
    onSetup() {
      provide(DefaultApolloClient, apolloClient);
    },
  },
});
