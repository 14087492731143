<template>
  <div class="v-product-header">
    <div class="v-product-header-content">
      <div class="v-product-header-left">
        <div class="container">
          <div class="row">
            <div class="col s12 l6 xl5 offset-xl1">
              <div class="v-product-header-text">
                <h1 v-html="formattedTitle"></h1>
                <h3>{{ subtitle }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="v-product-header-right">
        <FlyImage :attachment-id="backgroundImage" preset="vue-big-product-image" :aspect-ratio="1.94" />
      </div>
      <div class="v-product-header-features">
        <div class="container">
          <div class="row">
            <div class="col s12 l6 offset-xl1">
              <div class="v-product-header-features-inner">
                <slot />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="v-product-header-bottom">
        <div class="container">
          <div class="row">
            <div class="col s12 xl10 offset-xl1">
              <div class="v-product-header-bottom-toolbar">
                <ConfigureNowButton href="#tisch-konfigurieren" />
                <ProductAusfuehrungSelector />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="v-product-header-product-images">
      <div class="container">
        <div class="v-product-header-product-images-wrapper">
          <FlyImage v-for="attachmentId in productImages" :attachment-id="attachmentId" :key="attachmentId" preset="vue-header-galery" aspect-ratio="3/2" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue';
import { VARIANT_NAMES } from '@/js/vue/shopify/keys';
import { useConfiguratorStore } from '@/js/vue/shopify/stores/configurator';
import { useSettingsStore } from '@/js/vue/shopify/stores/settings';
import FlyImage from '@/js/vue/components/FlyImage.vue';
import ProductAusfuehrungSelector from '@/js/vue/components/Configurator/ProductAusfuehrungSelector.vue';
import ConfigureNowButton from '@/js/vue/components/Configurator/ConfigureNowButton.vue';

const { title, subtitle, productImages } = defineProps<{
  title: string;
  subtitle?: string;
  productImages?: number[];
}>();

// Computed Property für das formatierte HTML

const formattedTitle = computed(() => {
  if (!title) return '';
  return title.replace(/\/(.*?)\//g, '<span class="highlight">$1</span>');
});

const configurator = useConfiguratorStore();
const settings = useSettingsStore();

const backgroundImage = computed<number | undefined>(() =>
  settings.findProductImageForAusfuehrung(configurator.products.main.product, configurator.selectedProductOptions.main[VARIANT_NAMES['Ausfuehrung']], 'product_header_main')
);
</script>

<style lang="scss" scoped>
.v-product-header {
  background: $wbeige;
  padding-top: 100px;
  position: relative;
  @include media(XL) {
    padding-top: 80px;
  }
  @include media(XL1200) {
    padding-top: 60px;
  }
  @include media(S) {
    padding-top: 45px;
  }

  .v-product-header-content {
    position: relative;
    display: flex;
    flex-direction: column;

    .v-product-header-left {
      .v-product-header-text {
        display: flex;
        flex-direction: column;
        gap: 20px;
        @include media(S) {
          gap: 15px;
        }

        > * {
          margin: 0;
          text-align: left;
        }
        h1 {
          font-weight: 300;
          font-size: 42px;
          line-height: 1.24;
          @include media(XL1200) {
            font-size: 38px;
          }
          @include media(L) {
            font-size: 34px;
          }
          @include media(S) {
            font-size: 30px;
          }
        }
        h3 {
          font-weight: 400;
        }
      }
    }
    .v-product-header-right {
      position: absolute;
      right: 0;
      width: calc(50% - 100px);
      bottom: 0;
      z-index: 1;
      @include media(XXL) {
        width: calc(50% - 30px);
      }
      @include media(L) {
        width: calc(100% - 30px);
        position: relative;
        margin: 60px 0 0 30px;
      }
      @include media(S) {
        margin-top: 50px;
      }

      .v-fly-image {
        display: block;
      }
      &:before {
        @include media(L) {
          content: '';
          position: absolute;
          left: -100px;
          right: -100px;
          bottom: -10vw;
          height: 28vw;
          background: $wbeige-dark;
          transform: rotate(6.5deg);
        }
        @include media(S) {
          height: 36vw;
        }
      }
    }

    .v-product-header-features {
      position: relative;
      overflow: hidden;
      @include media(L) {
        order: 3;
      }

      &:before {
        content: '';
        position: absolute;
        left: -100px;
        right: -100px;
        bottom: -100px;
        height: 22vw;
        background: $wbeige-dark;
        transform: rotate(6.5deg);
        max-height: 380px;
        @include media(XXL) {
          height: 23vw;
        }
        @include media(XL1200) {
          height: 40vh;
        }
        @include media(L) {
          bottom: 0;
          top: 0;
          transform: none;
          height: 100%;
          max-height: none;
        }
      }
      .v-product-header-features-inner {
        display: flex;
        gap: 60px;
        padding: 14.8vh 0 8.3vh;
        position: relative;
        @include media(XL1200) {
          gap: 40px;
        }
        @include media(L) {
          padding: 80px 0;
          flex-direction: column;
          gap: 30px;
        }
        @include media(M) {
          padding: 60px 0;
        }
        @include media(S) {
          padding: 50px 0;
        }
      }
    }
  }

  .v-product-header-bottom {
    position: relative;
    z-index: 1;
    @include media(L) {
      order: 2;
      position: initial;
      margin-top: -50px;
      background: $wbeige-dark;
    }
    @include media(M) {
      margin-top: -40px;
    }
    @include media(S) {
      margin-top: -20px;
    }

    .v-configure-now-button {
      position: absolute;
      transform: translate(0, -50%);
      @include media(L) {
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
      }
    }
    .v-ausfuherung-selector {
      position: absolute;
      right: 0;
      width: calc(50% - 100px);
      transform: translate(0, -50%);
      mask-image: linear-gradient(-90deg, rgba(0, 0, 0, 0) 0%, black 50px);
      overflow: auto;
      scrollbar-color: rgba($wbrown, 0.3) rgba(0, 0, 0, 0.1);
      scrollbar-width: thin;
      padding-right: 50px;
      @include media(XXL) {
        width: calc(50% - 30px);
      }
      @include media(L) {
        width: 100%;
        position: relative;
        padding-right: 30px;
        transform: translate(0, 0);
      }
    }
  }

  .v-product-header-product-images {
    padding: 13.9vh 0 7.4vh;
    background: $wbrown;
    @include media(L) {
      padding: 80px 0;
    }

    .v-product-header-product-images-wrapper {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px;
      row-gap: 30px;
      @include media(S) {
        margin: 0 -20px;
      }

      .v-fly-image {
        width: 50%;
        padding: 0 15px;
        @include media(L) {
          width: 100%;
        }
        @include media(S) {
          padding: 0;
          padding-right: 20px;
        }

        &:nth-child(even) {
          @include media(S) {
            padding-right: 0;
            padding-left: 20px;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.v-product-header {
  .v-product-header-right .v-fly-image {
    width: 200%;
    max-width: none;
    max-height: 780px;
    object-fit: contain;
    @include media(XL1200) {
      width: 250%;
      max-height: 650px;
    }
    @include media(L) {
      width: 150%;
      max-height: 50vh;
    }
    @include media(S) {
      width: 200%;
    }

    img {
      max-height: 100%;
      max-width: none;
      width: auto;
      position: absolute;
    }
  }

  .v-product-header-text h1 .highlight {
    font-weight: 700;
    color: $primary;
  }
}


</style>
