<template>
  <div class="v-product-selection">
    <div class="v-product-selection-wrapper">
      <div class="v-product-selection-header">
        <div class="container">
          <div class="row">
            <div class="col s12 l10 xl8 offset-l1 offset-xl2">
              <h1 class="v-product-selection-title">{{ title }}</h1>
              <div class="v-product-selection-lead h6">{{ lead }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="v-product-selection-images">
        <div class="container">
          <div class="v-product-selection-images__wrapper" :class="{ 'full-width--left': leftSelectedAusfuherung, 'full-width--right': rightSelectedAusfuherung }">
            <div class="v-product-selection-circle"></div>
            <FlyImage
              class="v-product-selection-preview-left"
              :attachment-id="leftBackgroundImage"
              preset="vue-fullscreen-product-image"
              object-fit="cover"
              :aspect-ratio="null"
            />
            <FlyImage
              class="v-product-selection-preview-right"
              :attachment-id="rightBackgroundImage"
              preset="vue-fullscreen-product-image"
              object-fit="cover"
              :aspect-ratio="null"
            />
          </div>
        </div>
      </div>

      <div class="v-product-selection-variants-wrapper">
        <div class="v-product-selection-variants-inner">
          <div class="v-product-selection-variants" v-if="leftProductQuery.product.value">
            <ProductVariantBox
              v-for="variantValue in leftProductQuery.productOptions.value[VARIANT_NAMES.Ausfuehrung]"
              :key="variantValue"
              :product="leftProductQuery.product.value"
              :variant-name="VARIANT_NAMES.Ausfuehrung"
              :variant-value="variantValue"
              show-preview-image-skeleton
              :is-selected="leftSelectedAusfuherung === variantValue"
              theme="light"
              @click="
                () => {
                  leftSelectedAusfuherung = variantValue;
                  rightSelectedAusfuherung = undefined;
                  productDetailsOpen = true;

                  logEvent('productSelectionVariantSelected', {
                    productId: leftProductQuery.product.value?.id,
                    variantName: VARIANT_NAMES.Ausfuehrung,
                    variantValue,
                  });
                }
              "
            />
          </div>

          <div class="v-product-selection-variants" v-if="rightProductQuery.product.value">
            <ProductVariantBox
              v-for="variantValue in rightProductQuery.productOptions.value[VARIANT_NAMES.Ausfuehrung]"
              :key="variantValue"
              :product="rightProductQuery.product.value"
              :variant-name="VARIANT_NAMES.Ausfuehrung"
              :variant-value="variantValue"
              show-preview-image-skeleton
              :is-selected="rightSelectedAusfuherung === variantValue"
              theme="light"
              @click="
                () => {
                  leftSelectedAusfuherung = undefined;
                  rightSelectedAusfuherung = variantValue;
                  productDetailsOpen = true;

                  logEvent('productSelectionVariantSelected', {
                    productId: leftProductQuery.product.value?.id,
                    variantName: VARIANT_NAMES.Ausfuehrung,
                    variantValue,
                  });
                }
              "
            />
          </div>
        </div>
      </div>
    </div>

    <Transition name="slide-right">
      <ProductSelectionDetails
        v-if="leftSelectedAusfuherung && productDetailsOpen"
        :product="leftProductQuery.product.value"
        :product-ausfuehrung="leftSelectedAusfuherung"
        position="right"
        :configure-now-url="left.configure_now_url"
        @close="() => (productDetailsOpen = false)"
      />
    </Transition>

    <Transition name="slide-left">
      <ProductSelectionDetails
        v-if="rightSelectedAusfuherung && productDetailsOpen"
        :product="rightProductQuery.product.value"
        :product-ausfuehrung="rightSelectedAusfuherung"
        position="left"
        :configure-now-url="right.configure_now_url"
        @close="() => (productDetailsOpen = false)"
      />
    </Transition>

    <div
        v-if="productDetailsOpen && (leftSelectedAusfuherung || rightSelectedAusfuherung)"
        class="v-product-selection-details-background-close"
        @click="() => (productDetailsOpen = false)"
    ></div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, PropType, ref } from 'vue';
import { useProductQuery } from '@/js/vue/shopify/stores/configurator';
import { VARIANT_NAMES } from '@/js/vue/shopify/keys';
import ProductVariantBox from '@/js/vue/components/Configurator/ProductVariantBox.vue';
import { useSettingsStore } from '@/js/vue/shopify/stores/settings';
import FlyImage from '@/js/vue/components/FlyImage.vue';
import ProductSelectionDetails from '@/js/vue/components/Configurator/ProductSelectionDetails.vue';
import { logEvent } from '@/js/plugins/analytics';

const props = defineProps({
  title: {
    type: String,
  },
  lead: {
    type: String,
  },
  left: {
    type: Object as PropType<{
      product_id: number;
      product_variant_ausfuehrung_whitelist: string[];
      configure_now_url: string;
    }>,
  },
  right: {
    type: Object as PropType<{
      product_id: number;
      product_variant_ausfuehrung_whitelist: string[];
      configure_now_url: string;
    }>,
  },
});

const leftProductQuery = useProductQuery();
const rightProductQuery = useProductQuery();

const leftSelectedAusfuherung = ref<string>();
const rightSelectedAusfuherung = ref<string>();
const productDetailsOpen = ref(false);

const settings = useSettingsStore();

if (props.left.product_id) {
  leftProductQuery.loadProduct(props.left.product_id, props.left.product_variant_ausfuehrung_whitelist);
}

if (props.right.product_id) {
  rightProductQuery.loadProduct(props.right.product_id, props.right.product_variant_ausfuehrung_whitelist);
}

const leftBackgroundImage = computed<number | undefined>(() =>
  leftProductQuery.product.value
    ? settings.findProductImageForAusfuehrung(
        leftProductQuery.product.value,
        leftSelectedAusfuherung.value ?? leftProductQuery.productOptions.value[VARIANT_NAMES.Ausfuehrung].at(0),
        'product_selection_main'
      )
    : undefined
);

const rightBackgroundImage = computed<number | undefined>(() =>
  rightProductQuery.product.value
    ? settings.findProductImageForAusfuehrung(
        rightProductQuery.product.value,
        rightSelectedAusfuherung.value ?? rightProductQuery.productOptions.value[VARIANT_NAMES.Ausfuehrung].at(0),
        'product_selection_main'
      )
    : undefined
);

const escHandler = (e: KeyboardEvent) => {
  if ((leftSelectedAusfuherung.value || rightSelectedAusfuherung.value) && e.key === 'Escape') {
    productDetailsOpen.value = false;
  }
};

onMounted(() => {
  window.addEventListener('keydown', escHandler);

  return () => window.removeEventListener('keydown', escHandler);
});
</script>

<style lang="scss" scoped>
.v-product-selection {
  background: $wbeige;
  padding: 80px 0 0;
  position: relative;
  @include media(XL1200) {
    padding: 60px 0 0;
  }
  @include media(S) {
    padding: 45px 0 0;
  }

  .v-product-selection-wrapper {
    overflow: hidden;
  }
  .v-product-selection-header {
    h1,
    .h6 {
      @include media(L) {
        text-align: left;
      }
    }
    .h6 {
      margin-top: 20px;
    }
  }
  .v-product-selection-images {
    margin-top: 60px;
    position: relative;

    .v-product-selection-circle {
      width: 730px;
      height: 730px;
      border-radius: 100%;
      background: $wbeige-dark;
      position: absolute;
      bottom: 0;
      right: 90px;
      transform: translate(50%, 50%);
      @include media(XXL) {
        width: 600px;
        height: 600px;
        right: 120px;
      }
      @include media(M) {
        width: 450px;
        height: 450px;
        right: 90px;
      }
      @include media(S) {
        width: 350px;
        height: 350px;
        right: 70px;
      }
    }
    .v-product-selection-images__wrapper {
      margin: 0 -125px;
      display: flex;
      position: relative;
      height: 515px;

      @include media(XXL) {
        margin: 0 -50px;
        height: 475px;
      }
      @include media(XL1200) {
        height: 415px;
      }
      @include media(L) {
        height: 450px;

        .v-fly-image {
          --object-fit: contain !important;
        }
      }
      @include media(M) {
        height: 370px;
      }
      @include media(S) {
        height: auto;
        aspect-ratio: 1.6;
      }


      &.full-width--left {
        .v-product-selection-preview-left {
          mask-size: 100%;
        }
        .v-product-selection-preview-right {
          mask-size: 0%;
        }
      }

      &.full-width--right {
        .v-product-selection-preview-right {
          mask-size: 100%;
        }
        .v-product-selection-preview-left {
          mask-size: 0%;
        }
      }

      .v-product-selection-preview-left,
      .v-product-selection-preview-right {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;
        transition: mask-size 1s ease;
        mask-image: linear-gradient(to left, black 100%, transparent 0%);
        mask-repeat: no-repeat;
        mask-size: 50%;

        :global(img) {
          object-position: center top;
        }
      }

      .v-product-selection-preview-left {
        mask-position: left;
      }

      .v-product-selection-preview-right {
        mask-position: right;
      }
    }
  }

  .v-product-selection-variants-wrapper {
    position: absolute;
    bottom: 50px;
    right: 0;
    left: 50px;
    max-width: 1500px;
    margin: 0 auto;
    mask-image: linear-gradient(-90deg, rgba(0, 0, 0, 0) 0%, black 50px);
    @include media(XL1200) {
      bottom: 30px;
    }
    @include media(L) {
      position: relative;
      left: 0;
      margin: 0 30px;
    }
    @include media(S) {
      margin: 0 15px;
    }

    .v-product-selection-variants-inner {
      display: flex;
      justify-content: space-between;
      gap: 50px;
      overflow: auto;
      padding-right: 50px;
      scrollbar-color: rgba($wbrown, 0.3) rgba(0, 0, 0, 0.1);
      scrollbar-width: thin;
      @include media(L) {
        padding-right: 30px;
      }
      @include media(S) {
        padding-right: 15px;
      }

      .v-product-selection-variants {
        display: flex;
        gap: 20px;
        @include media(XL) {
          gap: 15px;
        }
      }
    }
  }
}

.v-product-selection-details-background-close {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  background: rgba($black, 0.5);
  cursor: pointer;
  @include media(L) {
    z-index: 110;
    position: fixed;
  }
}

.slide-right-enter-active,
.slide-left-enter-active {
  transition: all 0.2s ease-out;
}
.slide-right-leave-active,
.slide-left-leave-active {
  transition: all 0.2s $cubic-bezier;
}
.slide-right-enter-from,
.slide-right-leave-to {
  transform: translateX(50%);
  opacity: 0;
}
.slide-left-enter-from,
.slide-left-leave-to {
  transform: translateX(-50%);
  opacity: 0;

  @include media(L) {
    transform: translateX(50%);
  }
}
</style>
