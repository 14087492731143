import $ from 'jquery';
import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';



class MainSwiper {
  constructor() {


    var reviewSwiper = new Swiper('.swiper-container-review', {
      modules: [Navigation],
      // Optional parameters
      direction: 'horizontal',
      loop: true,
      lazy: true,
      slidesPerView: 'auto',
      spaceBetween: 30,
      grabCursor: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });


    var boxSwiper = new Swiper('.swiper-container-testimonial', {
      modules: [Pagination, Autoplay],
      // Optional parameters
      direction: 'horizontal',
      loop: true,
      lazy: true,
      speed: 1500,
      slidesPerView: 1,
      autoplay: {
        delay: 4000,
      },
      breakpoints: {
        601: {
          slidesPerView: 2,
        },
        993: {
          slidesPerView: 3,
        },
      },
      grabCursor: true,
      pagination: {
        el: '.swiper-pagination.testimonial',
        clickable: true,
      }
    });

  }
}

export default new MainSwiper();
