<template>
  <div class="v-configuration-floating-header" :class="{ visible }">
    <div class="container">
      <div class="v-content-wrapper">
        <div class="v-content-title">
          <div class="font--thin">{{ labels.floating_header_text }}</div>
          <strong>{{ labels.floating_header_pre_text }} rustikalem Eichenholz</strong>
        </div>
        <div class="v-content-actions">
          <strong class="v-content-price">{{ store.totalCurrencyCode }} {{ store.totalPrice.toFixed(2) }}</strong>
          <div class="v-content-actions__right">
            <CartButton />
            <AddToCartButton />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useConfiguratorStore } from '../../shopify/stores/configurator';
import AddToCartButton from './AddToCartButton.vue';
import CartButton from '@/js/vue/components/Configurator/CartButton.vue';
import { computed } from 'vue';
import { useSettingsStore } from '@/js/vue/shopify/stores/settings';

const settings = useSettingsStore();
const labels = computed(() => settings.labels.configurator);
const store = useConfiguratorStore();

defineProps({
  visible: {
    type: Boolean,
    default: () => true,
  },
});
</script>

<style lang="scss" scoped>
.v-configuration-floating-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background: $blue-light;
  color: $white;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  transition: opacity $transition-duration $cubic-bezier, transform $transition-duration $cubic-bezier;
  transform: translateY(-100%);

  &.visible {
    transform: translateY(0);
  }

  .v-cart-button {
    color: $white;

    &:hover {
      background: $white;
      color: $primary;
      border-color: $white;
    }
    .cart-items-counter {
      outline-color: $primary;
      background: red;
    }
  }
  .v-add-to-cart-button {
    background: $wblue-dark;

    &:hover {
      background: $white;
      color: $wblue-dark;
    }
  }
}

.v-content-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 96px;
  @include media(XL1200) {
    height: 92px;
  }
}

.v-content-title {
  font-size: 24px;
  line-height: 1.2;
  @include media(XL1200) {
    font-size: 20px;
  }
  @include media(L) {
    display: none;
  }

  strong {
    font-weight: 700;
  }
}

.v-content-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 25px;
  @include media(L) {
    justify-content: space-between;
    width: 100%;
  }
  @include media(S) {
    flex-direction: column-reverse;
    gap: 10px;
    align-items: flex-start;
  }

  .v-content-actions__right {
    display: flex;
    align-items: center;
    gap: 25px;
    @include media(S) {
      justify-content: space-between;
      flex-direction: row-reverse;
      width: 100%;
    }
  }
}

.v-content-price {
  font-size: 22px;
  line-height: 1;
  @include media(M) {
    font-size: 20px;
  }
  @include media(S) {
    font-size: 16px;
  }
}
</style>

<style lang="scss">
.v-configuration-floating-header .v-cart-button .cart-items-counter {
  outline-color: $primary;
  background: $white;
  color: $primary;
}
</style>