import { defineStore } from 'pinia';
import { useLocalStorage } from '@vueuse/core';
import { provideApolloClient, useMutation, useQuery } from '@vue/apollo-composable';
import { GET_CART } from '@/js/vue/shopify/queries';
import { apolloClient } from '@/js/vue/shopify/apollo';
import { ADD_LINE_ITEMS, CREATE_CART, UPDATE_LINE_ITEMS } from '@/js/vue/shopify/mutations';
import { computed, ref, watch } from 'vue';
import { logEvent } from '@/js/plugins/analytics';

export const useCartStore = defineStore('cart', () => {
  const sidebarOpened = ref(false);
  const cartId = useLocalStorage('cart_id', null);
  const cartQuery = provideApolloClient(apolloClient)(() =>
    useQuery(
      GET_CART,
      {
        id: cartId.value ?? undefined,
      },
      {
        prefetch: !!cartId.value,
      }
    )
  );

  const createCartMutation = provideApolloClient(apolloClient)(() => useMutation(CREATE_CART));
  const addLineItemsMutation = provideApolloClient(apolloClient)(() => useMutation(ADD_LINE_ITEMS));
  const updateLineItemsMutation = provideApolloClient(apolloClient)(() => useMutation(UPDATE_LINE_ITEMS));

  const isMutatingCart = ref(false);
  const isEmpty = computed(() => !lineItems.value?.length);

  const refresh = async () => {
    if (!cartId.value) {
      return createCartMutation.mutate().then(({ data }) => {
        cartId.value = data?.cartCreate?.cart?.id ?? null;
        cartQuery.refetch({
          id: cartId.value,
        });
      });
    } else {
      return cartQuery.refetch({
        id: cartId.value,
      });
    }
  };

  const lineItems = computed<any[]>(() => {
    const edges = cartQuery.result.value?.cart.lines.edges ?? [];

    return edges;
  });

  const clearLineItems = async () => {
    isMutatingCart.value = true;

    return updateLineItemsMutation.mutate({
      cartId: cartId.value,
      lines: (cartQuery.result.value?.cart.lines.edges ?? []).map((line) => ({
        id: line.node.id,
        quantity: 0,
      })),
    });
  };

  const addLineItems = async (variants: string[]) => {
    await clearLineItems();

    const lines = variants.map((id) => ({
      quantity: 1,
      merchandiseId: id,
    }));

    lines.reverse();

    addLineItemsMutation
      .mutate({
        cartId: cartId.value,
        lines: lines,
      })
      .then(() => refresh())
      .finally(() => {
        isMutatingCart.value = false;
        showSidebar();

        logEvent('addedCartLineItems', {
          variants,
          totalPrice: parseFloat(cartQuery.result.value?.cart.cost.totalAmount.amount ?? '0'),
        });
      });
  };

  const showSidebar = () => {
    sidebarOpened.value = true;

    logEvent('cartSidebarOpened');
  };

  const hideSidebar = () => {
    sidebarOpened.value = false;

    logEvent('cartSidebarClosed');
  };

  watch(sidebarOpened, (open) => {
    const TawkWidget = document.querySelector('script + .widget-visible > iframe') as HTMLElement | undefined;

    if (TawkWidget) {
      TawkWidget.style.visibility = open ? 'hidden' : 'visible';
    }
  });

  refresh();

  return {
    cartId,
    cartQuery,
    refresh,
    isEmpty,
    lineItems,
    addLineItems,
    isMutatingCart,
    sidebarOpened,
    showSidebar,
    hideSidebar,
  };
});
