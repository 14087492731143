<template>
  <div class="v-fly-image" :class="{ skeleton: !html }" v-html="html" :style="{ aspectRatio, '--object-fit': objectFit }"></div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import axios from 'axios';
import { useSessionStorage } from '@vueuse/core';

const props = defineProps({
  attachmentId: {
    type: [Number, Boolean],
  },
  preset: {
    type: String,
    required: false,
  },
  aspectRatio: {
    type: [String, Number],
    default: () => '3/2',
  },
  objectFit: {
    type: String,
    default: () => 'cover',
  },
});

const html = ref(null);

watch(
  () => props.attachmentId,
  (attachmentId, oldValue) => {
    if (!attachmentId) {
      html.value = null;
    } else if (attachmentId && attachmentId !== oldValue) {
      const flyImageCache = useSessionStorage(`fly-image-cache-${props.attachmentId}-${props.preset ?? 'default'}`, null);

      if (flyImageCache.value) {
        html.value = flyImageCache.value;
      }

      axios
        .get(`/!/vue-image/${attachmentId}`, {
          params: {
            preset: props.preset,
          },
        })
        .then((res) => {
          if (attachmentId === props.attachmentId) {
            html.value = res.data;
            flyImageCache.value = res.data;
          }
        });
    }
  },
  {
    immediate: true,
  }
);
</script>

<style lang="scss" scoped>
.v-fly-image {
  aspect-ratio: var(--aspect-ratio);
  position: relative;
  display: flex;

  :global(img) {
    width: 100%;
    height: auto;
    object-fit: var(--object-fit);
  }
}

.v-fly-image.skeleton {
  @keyframes skeleton-loading {
    to {
      background-position: left;
    }
  }

  cursor: progress !important;
  background: linear-gradient(90deg, #ddda 40%, #efefefaa, #ddda 60%) right / 300% 100%;
  animation: skeleton-loading 1.5s linear infinite;
}

.round {
  border-radius: 100%;
  overflow: hidden;
}
</style>
