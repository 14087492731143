<template>
  <div class="v-cart-button" @click="cart.showSidebar()">
    <svg class="v-cart-icon" width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 38C20.5523 38 21 37.5523 21 37C21 36.4477 20.5523 36 20 36C19.4477 36 19 36.4477 19 37C19 37.5523 19.4477 38 20 38Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31 38C31.5523 38 32 37.5523 32 37C32 36.4477 31.5523 36 31 36C30.4477 36 30 36.4477 30 37C30 37.5523 30.4477 38 31 38Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 17H16L18.68 30.39C18.7714 30.8504 19.0219 31.264 19.3875 31.5583C19.7532 31.8526 20.2107 32.009 20.68 32H30.4C30.8693 32.009 31.3268 31.8526 31.6925 31.5583C32.0581 31.264 32.3086 30.8504 32.4 30.39L34 22H17"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

    <span class="cart-items-counter">{{ numberOfItems }}</span>
  </div>
</template>

<script lang="ts" setup>
import { useCartStore } from '@/js/vue/shopify/stores/cart';
import { computed } from 'vue';


const cart = useCartStore();
const numberOfItems = computed(() => cart.lineItems.length);
</script>

<style lang="scss" scoped>
.v-cart-button {
  position: relative;
  width: 50px;
  height: 50px;
  cursor: pointer;
  color: $primary;
  transition: color 0.3s $cubic-bezier, background 0.3s $cubic-bezier, border 0.3s $cubic-bezier;
  border-radius: 100%;
  border: 2px solid;
  @include media(XL1200) {
    width: 40px;
    height: 40px;
  }

  .v-cart-border {
    position: absolute;
    left: 0;
    top: 0;
  }

  .v-cart-icon {
    transition: transform 0.3s $cubic-bezier;
    transform: scale(1) rotate(0);
    width: 100%;
    height: 100%;
  }

  &:hover {
    color: $white;
    background: $primary;
    border-color: $primary;

    .v-cart-icon {
      transform: scale(1.1) rotate(-6deg);
    }
  }

  .cart-items-counter {
    font-size: 14px;
    position: absolute;
    top: -2px;
    right: -2px;
    color: white;
    border-radius: 100%;
    outline: 3px solid white;
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    padding: 2px 0 0;
    background: $primary;
    @include media(XL1200) {
      font-size: 12px;
      height: 14px;
      width: 14px;
      outline: 2px solid white;
      padding: 2px 0 0;
    }
  }
}
</style>
