<template>
  <div v-if="store.products.main.product" class="v-configurator-sections">
    <h3>{{ labels.config_title }}</h3>
    <div class="variants-section" v-for="(section, index) in store.sections" :key="index">
      <h4>{{ index + 1 }}. {{ section.title }}</h4>
      <div class="variants-wrapper" :class="{ 'keep-columns-on-mobile': section.keepColumnsOnMobile }">
        <div class="variants-outer-container" v-for="variant in section.variants" :key="variant.variantName">
          <div v-if="section.variants.length > 1" class="variant-display-name-wrapper">
            <FlyImage v-if="variant.variantIcon" :attachment-id="variant.variantIcon" :aspect-ratio="1 / 1" preset="vue-variant-icon" />
            <span>{{ variant.variantDisplayName }}</span>
          </div>
          <div class="variants-container" :class="{ horizontal: variant.horizontal, 'keep-vertical-on-mobile': variant.horizontalVariantBoxOnMobile }">
            <template v-for="variantValue in store.products[variant.productKey].productOptions[VARIANT_NAMES[variant.variantName]]" :key="variantValue">
              <ProductVariant
                :productKey="variant.productKey"
                :variantName="VARIANT_NAMES[variant.variantName]"
                :variantValue="variantValue"
                :show-price-difference="variant.showPriceDifference"
                :show-preview-image-skeleton="variant.showPreviewImageSkeleton"
                :horizontal-variant-box-on-mobile="variant.horizontalVariantBoxOnMobile"
              />
            </template>

            <ProductVariant
              v-if="store.products[variant.productKey].product?.isOptional && variant.hideClearButton !== true"
              :variantName="VARIANT_NAMES[variant.variantName]"
              :product-key="variant.productKey"
            />
          </div>
        </div>
      </div>

      <div v-if="section.bottomText" class="section-bottom-text font--small">{{ section.bottomText }}</div>
    </div>

    <div class="v-bottom-section">
      <div class="v-bottom-price">
        {{ labels.total_text }} <strong>{{ store.totalCurrencyCode }} {{ store.totalPrice.toFixed(2) }}</strong>
      </div>
      <div class="v-bottom-price-hint">
        <div v-html="labels.total_description"></div>
      </div>

      <AddToCartButton />
      <PaymentMethods />
    </div>
  </div>
</template>

<script lang="ts" setup>
import ProductVariant from '@/js/vue/components/Configurator/ProductVariant.vue';
import FlyImage from '@/js/vue/components/FlyImage.vue';
import { VARIANT_NAMES } from '@/js/vue/shopify/keys';
import { useConfiguratorStore } from '@/js/vue/shopify/stores/configurator';
import { watch, computed } from 'vue';
import AddToCartButton from './AddToCartButton.vue';
import PaymentMethods from './PaymentMethods.vue';
import { useSettingsStore } from '@/js/vue/shopify/stores/settings';

const settings = useSettingsStore();
const labels = computed(() => settings.labels.configurator);
const store = useConfiguratorStore();

watch(
  () => [store.products],
  () => {
    store.selectCheapestProductOptions();
  },
  { deep: true, flush: 'post' }
);
</script>

<style lang="scss" scoped>
.v-configurator-sections {
  h3 {
    @include media(L) {
      margin-top: 0;
    }
  }
  .variants-section {
    margin-bottom: 80px;

    h4 {
      margin-bottom: 25px;
      padding-bottom: 15px;
      border-bottom: 1px solid;
      @include media(XL1200) {
        padding-bottom: 10px;
      }
      @include media(M) {
        margin-bottom: 20px;
        padding-bottom: 5px;
      }
    }
    .section-bottom-text {
      line-height: 1.36;
      margin-top: 30px;
      opacity: 0.6;
    }
  }
  .variants-wrapper {
    display: flex;
    gap: 30px;

    &:not(.keep-columns-on-mobile) {
      @include media(M) {
        flex-direction: column;
        gap: 40px;
      }
    }

    .variants-outer-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 40px;
      width: 100%;
      @include media(XL1200) {
        gap: 30px;
      }
      @include media(M) {
        gap: 25px;
      }

      .variant-display-name-wrapper {
        display: flex;
        align-items: center;
        gap: 20px;
        font-weight: 500;
        @include media(M) {
          gap: 15px;
        }

        .v-fly-image {
          width: 40px;
          height: 40px;
          align-items: center;
          justify-content: center;
          @include media(M) {
            width: 34px;
            height: 34px;
          }
        }
      }
      .variants-container {
        display: flex;
        gap: 15px;
        flex-direction: column;
        align-items: flex-start;

        &.horizontal {
          flex-direction: row;
          mask-image: linear-gradient(-90deg, rgba(0, 0, 0, 0) 0%, black 50px);
          overflow: auto;
          scrollbar-color: rgba($wbeige-dark, 0.3) rgba(0, 0, 0, 0.1);
          scrollbar-width: thin;
          padding-right: 50px;
          align-items: stretch;

          &.keep-vertical-on-mobile {
            @include media(M) {
              flex-direction: column;
              padding-right: 0;
              mask-image: none;
            }
          }
        }
      }
    }
  }
}

.v-bottom-section {
  .v-bottom-price {
    font-size: 24px;
    line-height: 1;
    padding: 20px 0 16px 0;
    border-top: 1px solid $white;
    border-bottom: 1px solid $white;
    @include media(XL1200) {
      font-size: 22px;
      padding: 18px 0 14px 0;
    }
    @include media(S) {
      font-size: 20px;
      padding: 15px 0 11px 0;
    }
  }

  .v-bottom-price-hint {
    font-size: 14px;
    line-height: 1.4;
    margin-top: 10px;
    margin-bottom: 40px;
    @include media(S) {
      margin-bottom: 30px;
    }
  }

  .v-payment-methods {
    margin-top: 15px;
  }
}
</style>

<style lang="scss">
.v-configurator-sections {
  .variants-container.horizontal .v-product-variant-box {
    width: auto;
  }
  .variant-display-name-wrapper .v-fly-image img {
    object-fit: contain;
    max-height: 100%;
  }
}
</style>
