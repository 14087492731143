export const VARIANT_NAMES = {
  Ausfuehrung: 'Ausführung',
  Laenge: 'Länge',
  Breite: 'Breite',
  Farbe: 'Farbe',
  AnzahlBeine: 'Anzahl Beine',
  Typ: 'Typ',
  Position: 'Position',
  Behandlung: 'Behandlung',
};
