<template>
  <div class="v-product-images">
    <div class="v-big-product-image">
      <template v-for="imageName in visibleProductImageNames" :key="imageName">
        <FlyImage :class="{ visible: selectedProductImageName === imageName }" aspect-ratio="1.94" :attachment-id="productImages[imageName]" preset="vue-configurator-product-image" />
      </template>
    </div>

    <div class="v-preview-images">
      <template v-for="imageName in visibleProductImageNames" :key="imageName">
        <FlyImage
          :attachment-id="productImages[imageName]"
          preset="vue-preview-image-landscape"
          aspect-ratio="3/2"
          @click="selectedProductImageName = imageName"
          :class="{ active: selectedProductImageName === imageName }"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useConfiguratorStore } from '@/js/vue/shopify/stores/configurator';
import { ProductImageName, useSettingsStore } from '@/js/vue/shopify/stores/settings';
import { computed, ref } from 'vue';
import { VARIANT_NAMES } from '@/js/vue/shopify/keys';
import FlyImage from '@/js/vue/components/FlyImage.vue';

const configurator = useConfiguratorStore();
const settings = useSettingsStore();

const visibleProductImageNames: ProductImageName[] = [
  'hauptbild_von_vorne',
  'ansicht_material_von_oben',
  'ansicht_tischplatte_auf_kabeldurchfuhrung_schlitz',
  'ansicht_tischplatte_auf_kabeldurchfuhrung_runde_offnung',
  'produktbild_5',
] as ProductImageName[];

const productImages = computed<Record<string, number | false>>(
  () =>
    settings
      .findProductSettings(configurator.products.main.product)
      ?.product_variant_settings?.filter((v) => v.product_ausfuehrung === configurator.selectedProductOptions.main[VARIANT_NAMES['Ausfuehrung']])
      .at(0)?.images ?? {}
);

const selectedProductImageName = ref<ProductImageName>('hauptbild_von_vorne');
</script>

<style lang="scss" scoped>
.v-product-images {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5vh;
  width: 100%;
  @include media(L) {
    gap: 50px;
  }
  @include media(M) {
    gap: 30px;
  }

  .v-big-product-image {
    position: relative;
    width: 100%;
    height: 100%;

    .v-fly-image {
      position: absolute;
      opacity: 0;
      transition: opacity 0.3s $cubic-bezier;
      bottom: 0;
      height: 100%;
      right: 0;
      @include media(XL) {
        max-height: 700px;
      }

      &.visible {
        opacity: 1;
        z-index: 10;
      }
    }
  }

  .v-preview-images {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    padding-left: 30px;
    width: 100%;
    @include media(XXL) {
      gap: 15px;
    }
    @include media(XL) {
      gap: 10px;
    }
    @include media(L) {
      justify-content: center;
    }

    .v-fly-image {
      outline: 5px solid $white;
      margin: 5px;
      cursor: pointer;
      width: 80px;
      @include media(XXL) {
        outline-width: 3px;
      }
    }

    .active {
      outline-color: $wbrown;
    }
  }
}

</style>

<style lang="scss">
.v-big-product-image img {
  object-fit: contain;
  object-position: bottom right;
}
</style>