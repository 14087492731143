import { gql } from '@apollo/client/core';

export const CREATE_CART = gql`
mutation {
  cartCreate(
    input: {
        #
    }
  ) {
    cart {
      id
    }
  }
}
`;

export const ADD_LINE_ITEMS = gql`
  mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
      cart {
        id
      }
      userErrors {
        field
        message
      }
    }
  }
`;

export const UPDATE_LINE_ITEMS = gql`
  mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        id
      }
      userErrors {
        field
        message
      }
    }
  }
`;
