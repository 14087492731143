import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import axios from 'axios';
import { Product } from '@/js/vue/shopify/queries';
import { MappedVariantName, VariantName } from '@/js/vue/shopify/types';
import { VARIANT_NAMES } from '@/js/vue/shopify/keys';
import { useLocalStorage } from '@vueuse/core';

export interface VariantSetting {
  variant_value: string;
  product_ausfuehrung: string;
  image_preview: number;
  description: string;
}

export type ProductImageName =
  | 'hauptbild_von_vorne'
  | 'ansicht_material_von_oben'
  | 'ansicht_tischplatte_auf_kabeldurchfuhrung_schlitz'
  | 'ansicht_tischplatte_auf_kabeldurchfuhrung_runde_offnung'
  | 'produktbild_5'
  | 'product_header_main'
  | 'product_header_feature_1'
  | 'product_header_feature_2'
  | 'product_selection_main'
  | 'product_selection_detail';

export interface ConfiguratorSettings {
  products?: Array<{
    product: string;
    product_variant_settings?: Array<{
      product_ausfuehrung: string;
      images: Record<ProductImageName, number>;
      description: string;
    }>;
    variants: Array<{
      variant_option_name: MappedVariantName;
      variant_option_settings: {
        icon: number;
        title: string;
        hint: string;
      };
      variant_settings: Array<VariantSetting>;
    }>;
  }>;
  labels?: {
    cart: {
      [key: string]: string;
    };
  };
}

export const useSettingsStore = defineStore('settings', () => {
  const settingsCache = useLocalStorage<ConfiguratorSettings>('configurator-settings-cache', {});
  const settings = ref<ConfiguratorSettings>(settingsCache.value);
  const labels = computed(() => (settings.value?.labels ?? { cart: {} }) as ConfiguratorSettings['labels']);

  axios.get('/!/configurator-settings').then((res) => {
    const data: ConfiguratorSettings = {
      products: res.data?.products.map((setting: any) => ({ ...setting, product_variant_settings: setting.product_variant_settings || undefined })) ?? [],
      labels: res.data?.labels,
    };

    settings.value = data;
    settingsCache.value = data;
  });

  const findProductSettings = (product: Product | undefined) => {
    return settings.value?.products?.find((value: any) => product?.id === `gid://shopify/Product/${value.product}`);
  };

  const findProductImageForAusfuehrung = (product: Product | undefined, productAusfuehrung: string, productImageName: ProductImageName) => {
    return (findProductSettings(product)
      ?.product_variant_settings?.filter((v) => v.product_ausfuehrung === productAusfuehrung)
      .at(0)?.images ?? {})[productImageName];
  };

  const findProductDescriptionForAusfuehrung = (product: Product | undefined, productAusfuehrung: string) => {
    return (
      findProductSettings(product)
        ?.product_variant_settings?.filter((v) => v.product_ausfuehrung === productAusfuehrung)
        .at(0)?.description ?? undefined
    );
  };

  const findVariantOptionSettings = (product: Product | undefined, variantName: VariantName) => {
    const variants = findProductSettings(product)?.variants;

    return variants
      ? variants.filter((v) => {
          return (VARIANT_NAMES[v.variant_option_name] ?? undefined) === variantName;
        }) ?? []
      : [];
  };

  const findVariantValueSetting = (product: Product, variantName: VariantName, variantValue: string, settingName: keyof VariantSetting, productAusfuehrung?: string): any => {
    return (
      (findVariantOptionSettings(product, variantName)
        .flatMap((os) => os.variant_settings)
        .filter((vs) => {
          if (!vs[settingName]) {
            return false;
          }

          if (productAusfuehrung && vs.product_ausfuehrung) {
            return vs.variant_value === variantValue && (vs.product_ausfuehrung === productAusfuehrung || !vs.product_ausfuehrung);
          }

          return vs.variant_value === variantValue;
        })
        .at(0) ?? {})[settingName] ?? undefined
    );
  };

  return {
    labels,
    findProductSettings,
    findProductImageForAusfuehrung,
    findProductDescriptionForAusfuehrung,
    findVariantOptionSettings,
    findVariantValueSetting,
  };
});
