import { defineStore } from 'pinia';
import { GET_PRODUCT, Product, ProductVariant, transformProductQuery } from '@/js/vue/shopify/queries';
import { apolloClient } from '@/js/vue/shopify/apollo';
import { provideApolloClient, useQuery, UseQueryReturn } from '@vue/apollo-composable';
import { computed, ComputedRef, reactive, ref, toRaw, watch } from 'vue';
import type { ProductKey } from '../types';
import { ConfiguratorSection, ConfiguratorSections } from '@/js/vue/shopify/configurator-sections';
import { useSettingsStore } from '@/js/vue/shopify/stores/settings';
import { VARIANT_NAMES } from '@/js/vue/shopify/keys';
import { logEvent } from '@/js/plugins/analytics';

export const findProductVariants = (product: Product | undefined, options: Record<string, string | undefined>): Array<ProductVariant> => {
  if (!product) {
    return [];
  }

  Object.keys(options).forEach((name) => {
    if (options[name] === undefined) {
      delete options[name];
    }
  });

  if (product.isOptional && !Object.keys(options).length) {
    return [];
  }

  return (
    product.variants
      ?.filter((v) => {
        const validOptions = v.selectedOptions.filter((selectedOption) => {
          return options[selectedOption.name] === selectedOption.value;
        });

        return validOptions.length === Object.keys(options).length;
      })
      .sort((a, b) => a.price.amount - b.price.amount) ?? []
  );
};

const getProductOptions = (product?: Product, productAusfuehrungWhitelist?: string[]) => {
  const groupedOptions: {
    [key: string]: string[];
  } = {};

  const allOptions = product?.variants?.map((v) => v.selectedOptions).flat();

  allOptions?.forEach((option) => {
    if (!Array.isArray(groupedOptions[option.name])) {
      groupedOptions[option.name] = [];
    }

    if (option.name === VARIANT_NAMES['Ausfuehrung'] && productAusfuehrungWhitelist?.length > 0 && !productAusfuehrungWhitelist.includes(option.value)) {
      return;
    }

    groupedOptions[option.name].push(option.value);
    groupedOptions[option.name] = [...new Set(groupedOptions[option.name])];
  });

  return groupedOptions;
};

export const useProductQuery = (): {
  product: ComputedRef<Product>;
  productQuery: UseQueryReturn<any, any>;
  productOptions: ComputedRef<ReturnType<typeof getProductOptions>>;
  loadProduct: (id: string | number, _productAusfuehrungWhitelist?: string[]) => Promise<any>;
} => {
  const productAusfuehrungWhitelist = ref<string[]>([]);

  const productQuery = provideApolloClient(apolloClient)(() =>
    useQuery(
      GET_PRODUCT,
      {
        productId: undefined as string,
      },
      {
        prefetch: false,
      }
    )
  );

  const product = computed(() => transformProductQuery(productQuery.result.value?.node));
  const productOptions = computed(() => getProductOptions(product.value, productAusfuehrungWhitelist.value));

  return {
    product,
    productOptions,
    productQuery,
    loadProduct: (id: string | number, _productAusfuehrungWhitelist?: string[]) => {
      productAusfuehrungWhitelist.value = _productAusfuehrungWhitelist;

      return productQuery.refetch({
        productId: `${id}`.startsWith('gid://') ? `${id}` : `gid://shopify/Product/${id}`,
      });
    },
  };
};

export const useConfiguratorStore = defineStore('configurator', () => {
  const initialized = ref(false);
  const settings = useSettingsStore();

  const products: Record<ProductKey, ReturnType<typeof useProductQuery>> = {
    main: useProductQuery(),
    tischbein: useProductQuery(),
    kabeldurchfuehrung: useProductQuery(),
    holzbehandlung: useProductQuery(),
  };

  const selectedProductOptions: Record<ProductKey, Record<string, string>> = reactive({
    main: {},
    tischbein: {},
    kabeldurchfuehrung: {},
    holzbehandlung: {},
  });

  const selectedVariants: Record<ProductKey, ComputedRef<ProductVariant | undefined>> = {
    main: computed(() => findProductVariants(products.main.product.value, selectedProductOptions.main).at(0)),
    tischbein: computed(() => findProductVariants(products.tischbein.product.value, selectedProductOptions.tischbein).at(0)),
    kabeldurchfuehrung: computed(() => findProductVariants(products.kabeldurchfuehrung.product.value, selectedProductOptions.kabeldurchfuehrung).at(0)),
    holzbehandlung: computed(() => findProductVariants(products.holzbehandlung.product.value, selectedProductOptions.holzbehandlung).at(0)),
  };

  const sections = computed(() =>
    ConfiguratorSections.map((s) => ({ ...s, variants: s.variants.filter((v) => !!products[v.productKey]?.product.value) } as ConfiguratorSection))
      .filter((s) => s.variants.length)
      .map((s) => ({
        ...s,
        variants: s.variants.map((v) => ({
          ...v,
          variantDisplayName:
            settings.findVariantOptionSettings(products.main.product.value, VARIANT_NAMES[v.variantName]).at(0)?.variant_option_settings.title ?? VARIANT_NAMES[v.variantName],
          variantIcon: settings.findVariantOptionSettings(products.main.product.value, VARIANT_NAMES[v.variantName]).at(0)?.variant_option_settings.icon,
        })),
      }))
      .map(
        (s) =>
          ({
            ...s,
            // Gathers the section title from the first title it finds in any variant of this section. Takes the pre-defined title as a default value.
            title:
              s.variants.length === 1
                ? s.variants
                    .flatMap((v) => settings.findVariantOptionSettings(products.main.product.value, VARIANT_NAMES[v.variantName]).at(0)?.variant_option_settings.title)
                    .filter(Boolean)
                    .at(0) ?? s.title
                : s.title,
            // Gathers the section bottom text from all variant option hint texts.
            bottomText: s.variants
              .flatMap((v) => settings.findVariantOptionSettings(products.main.product.value, VARIANT_NAMES[v.variantName]))
              .map((s) => s.variant_option_settings.hint)
              .filter(Boolean)
              .join('<br />'),
          } as ConfiguratorSection)
      )
  );

  const cheapestPriceAmount = computed<number>(() =>
    Object.values(products)
      .map((p) => p.product.value)
      .filter(Boolean)
      .map((p) => findProductVariants(p, {}).at(0)?.price.amount ?? 0)
      .reduce((acc, current) => acc + current, 0)
  );

  const cheapestPrice = computed<string>(() => {
    return 'CHF ' + cheapestPriceAmount.value.toFixed(2).replace('00', '-');
  });

  const totalPrice = computed(() =>
    Object.values(selectedVariants)
      .map((v) => v.value?.price.amount ?? 0)
      .reduce((acc, current) => acc + current, 0)
  );

  const totalCurrencyCode = computed(() => selectedVariants.main.value?.price.currencyCode ?? 'CHF');

  const selectCheapestProductOptions = () => {
    Object.keys(products).forEach((productKey) => {
      const query = products[productKey];

      if (
        (!query.product.value?.isOptional && !Object.keys(query.productOptions.value).length) ||
        (query.product.value?.isOptional && Object.keys(selectedProductOptions[productKey]).length === 0)
      ) {
        return;
      }

      Object.keys(query.productOptions.value).forEach((name) => {
        if (!!selectedProductOptions[productKey][name]) {
          return;
        }

        selectedProductOptions[productKey][name] = findProductVariants(query.product.value, selectedProductOptions[productKey])
          .at(0)
          ?.selectedOptions.find((o) => o.name === name)?.value;
      });
    });
  };

  watch(
    () => products.main.product.value,
    (p) => {
      Promise.all(
        ['tischbein', 'kabeldurchfuehrung', 'holzbehandlung'].map((productOptionKey) => {
          const productOptionValue = p[`product_option_${productOptionKey}`]?.value;

          if (!productOptionValue) {
            return Promise.resolve();
          }

          return products[productOptionKey].loadProduct(productOptionValue);
        })
      ).then(() => {
        initialized.value = true;
      });
    }
  );

  watch(
    () => selectedProductOptions,
    () => {
      if (!initialized.value) {
        return;
      }

      logEvent('configuratorSelectedOptionsChanged', {
        productId: products.main.product.value?.id,
        selectedProductOptions: toRaw(selectedProductOptions),
        cheapestPrice: cheapestPriceAmount.value,
        totalPrice: totalPrice.value,
      });
    },
    { deep: true }
  );

  return {
    sections,
    products,
    selectedProductOptions,
    selectedVariants,
    totalPrice,
    totalCurrencyCode,
    cheapestPriceAmount,
    cheapestPrice,
    selectCheapestProductOptions,
  };
});
