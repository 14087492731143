import { ApolloClient, createHttpLink } from '@apollo/client/core';
import { InMemoryCache } from '@apollo/client/cache';

const storefrontLink = createHttpLink({
  uri: 'https://woodu-desk.myshopify.com/api/2024-10/graphql.json',
  headers: {
    'X-Shopify-Storefront-Access-Token': 'b1a01b0968492ec52905efcad35be2a1',
  },
});

const cache = new InMemoryCache();

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: storefrontLink,
  cache,
});
