<template>
  <div class="button button--primary v-add-to-cart-button" :class="{ 'is-mutating': cart.isMutatingCart }" @click="handleAddToCart">
    <div class="v-spinner-wrapper" :class="{ visible: cart.isMutatingCart }">
      <InlineSpinner :size="18" />
    </div>
    <div class="v-button-text" v-if="!cart.lineItems.length">{{ labels.add_to_cart_button_text }}</div>
    <div class="v-button-text" v-else>{{ labels.change_cart_button_text }}</div>
  </div>
</template>

<script lang="ts" setup>
import { useCartStore } from '@/js/vue/shopify/stores/cart';
import { useConfiguratorStore } from '@/js/vue/shopify/stores/configurator';
import { InlineSpinner } from 'wly-theme-extensions';
import { computed } from 'vue';
import { useSettingsStore } from '@/js/vue/shopify/stores/settings';

const settings = useSettingsStore();
const labels = computed(() => settings.labels.cart);
const store = useConfiguratorStore();
const cart = useCartStore();

const handleAddToCart = () => {
  if (cart.isMutatingCart) {
    return;
  }

  const ids = Object.values(store.selectedVariants)
    .filter(Boolean)
    .map((v) => v.id);

  cart.addLineItems(ids);
};
</script>

<style lang="scss" scoped>
.v-add-to-cart-button {
  display: inline-flex;
  align-items: center;
  gap: 15px;

  &.is-mutating {
    pointer-events: none;
    filter: brightness(1.2) saturate(0.5) opacity(0.9);
  }
}

.v-button-text {
  margin-top: 3px;
}

.v-spinner-wrapper {
  width: 0;
  margin-top: -3px;
  margin-left: -15px;
  opacity: 0;
  overflow: hidden;
  transition: all $transition-duration $cubic-bezier;

  &.visible {
    width: 18px;
    margin-left: 0;
    opacity: 1;
  }
}
</style>
