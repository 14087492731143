<template>
  <div v-show="product" class="v-product-selection-details" :class="[`position-${position}`]" ref="el">
    <div class="v-close" @click="emit('close')">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M18 6L6 18" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M6 6L18 18" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>

    <div class="v-detail-content">
      <div class="v-detail-description" v-html="description ?? ''"></div>
      <FlyImage :attachment-id="productImage" preset="vue-product-detail-product-image" aspect-ratio="1.77" />
    </div>

    <div v-if="configureNowUrl" class="v-configure-now-button-container">
      <ConfigureNowButton :href="configureNowUrl" :productAusfuehrung="productAusfuehrung" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, PropType, ref } from 'vue';
import { Product } from '@/js/vue/shopify/queries';
import { useSettingsStore } from '@/js/vue/shopify/stores/settings';
import FlyImage from '@/js/vue/components/FlyImage.vue';
import ConfigureNowButton from '@/js/vue/components/Configurator/ConfigureNowButton.vue';
import { useSwipe } from '@vueuse/core';

const emit = defineEmits(['close']);

const props = defineProps({
  product: {
    type: Object as PropType<Product>,
    required: true,
  },
  productAusfuehrung: {
    type: String,
  },
  position: {
    type: String as PropType<'left' | 'right'>,
    default: () => 'right',
  },
  configureNowUrl: {
    type: String,
  },
});

const el = ref<HTMLElement>();
const settings = useSettingsStore();

const description = computed(() => settings.findProductDescriptionForAusfuehrung(props.product, props.productAusfuehrung));
const productImage = computed<number | undefined>(() => settings.findProductImageForAusfuehrung(props.product, props.productAusfuehrung, 'product_selection_detail'));

const {} = useSwipe(el, {
  onSwipeEnd(e, direction) {
    const styleLeft = (<any>el.value?.computedStyleMap().get('left'))?.value;

    if (direction === 'left' && styleLeft === 0) {
      emit('close');
    }

    if (direction === 'right' && styleLeft === 'auto') {
      emit('close');
    }
  },
});
</script>

<style lang="scss" scoped>
.v-product-selection-details {
  position: absolute;
  background: $wbrown;
  color: $white;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);
  top: 0;
  bottom: 0;
  width: 50%;
  padding: 80px 50px 50px 50px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  @include media(XL1200) {
    padding: 80px 30px 30px 30px;
  }
  @include media(L) {
    width: 80%;
    z-index: 111;
    position: fixed;
  }
  @include media(S) {
    width: calc(100% - 30px);
    padding: 74px 0 0;
  }

  .v-configure-now-button-container {
    position: absolute;
    bottom: 0;

    @include media(L) {
      bottom: 50px;
      left: 0;
      right: 0;
      transform: none !important;
      display: flex;
      justify-content: center;
    }

    @include media(S) {
      bottom: 30px;
    }
  }

  &.position-left {
    left: 0;

    @include media(L) {
      left: auto;
      right: 0;
    }

    .v-configure-now-button-container {
      right: 0;
      transform: translateX(50%) translateY(50%);
    }

    .v-close {
      left: unset;
      right: 24px;
      @include media(XL1200) {
        right: 16px;
      }
    }
  }

  &.position-right {
    right: 0;

    .v-configure-now-button-container {
      left: 0;
      transform: translateX(-50%) translateY(50%);
    }
  }
}

.v-detail-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  flex: 1;
  overflow-y: auto;

  .v-fly-image {
    flex-shrink: 1;
    overflow: hidden;
    min-height: 50%;
  }

  .v-detail-description {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left: 75px;
    max-width: 575px;
    @include media(XL1200) {
      padding-left: 0;
    }
    @include media(S) {
      padding: 0 30px;
    }

    > * {
      margin: 0;
    }
  }
}

.v-close {
  position: absolute;
  left: 24px;
  top: 24px;
  width: 54px;
  height: 54px;
  background: $white;
  border-radius: 100%;
  cursor: pointer;
  color: $wbrown;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background $transition-duration $cubic-bezier, color $transition-duration $cubic-bezier;
  @include media(XL1200) {
    left: auto;
    top: 16px;
    width: 44px;
    height: 44px;
    right: 16px;
  }

  &:hover {
    background: $primary;
    color: $white;
  }
}
</style>
