import { gql } from '@apollo/client/core';

export const GET_PRODUCT = gql`
  query getProduct($productId: ID!) {
    node(id: $productId) {
      id
      ... on Product {
        handle
        title
        description

        optionales_produkt: metafield(namespace: "custom", key: "optionales_produkt") {
          value
        }

        product_option_tischbein: metafield(namespace: "custom", key: "tischbein") {
          value
        }

        product_option_kabeldurchfuehrung: metafield(namespace: "custom", key: "kabeldurchfuehrung") {
          value
        }

        product_option_holzbehandlung: metafield(namespace: "custom", key: "holzbehandlung") {
          value
        }

        variants(first: 250) {
          edges {
            node {
              id
              title
              image {
                url
                width
                height
              }
              price {
                amount
                currencyCode
              }
              selectedOptions {
                name
                value
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_CART = gql`
  query getCart($id: ID!) {
    cart(id: $id) {
      id
      checkoutUrl
      createdAt
      updatedAt

      lines(first: 100) {
        edges {
          node {
            id
            quantity
            merchandise {
              ... on ProductVariant {
                id
                title

                product {
                  id
                  title
                  featuredImage {
                    url
                  }
                }
              }
            }
            cost {
              totalAmount {
                amount
                currencyCode
              }
            }
            attributes {
              key
              value
            }
          }
        }
      }
      attributes {
        key
        value
      }
      cost {
        totalAmount {
          amount
          currencyCode
        }
        subtotalAmount {
          amount
          currencyCode
        }
        totalTaxAmount {
          amount
          currencyCode
        }
        totalDutyAmount {
          amount
          currencyCode
        }
      }
    }
  }
`;

export interface ProductMetaField {
  value: string;
}

export const transformProductQuery = (node?: Record<string, any>) =>
  node
    ? {
        id: node.id as string,
        handle: node.handle as string,
        title: node.title as string,
        description: node.description as string,
        isOptional: node.optionales_produkt?.value === 'true',
        product_option_tischbein: node.product_option_tischbein as ProductMetaField | undefined,
        product_option_kabeldurchfuehrung: node.product_option_kabeldurchfuehrung as ProductMetaField | undefined,
        product_option_holzbehandlung: node.product_option_holzbehandlung as ProductMetaField | undefined,
        variants: transformProductVariantQuery(node.variants) as Array<ProductVariant>,
      }
    : undefined;

const transformProductVariantQuery = (
  variants?: Record<string, any>
): Array<{
  id: string;
  title: string;
  image: null | {
    url: string;
    width: number;
    height: number;
  };
  price: {
    amount: number;
    currencyCode: string;
  };
  selectedOptions: Array<{
    name: string;
    value: string;
  }>;
}> =>
  variants.edges?.map(({ node }) => ({
    id: node.id as string,
    title: node.title as string,
    image: node.image
      ? {
          url: node.image.url as string,
          width: node.image.width as number,
          height: node.image.height as number,
        }
      : null,
    price: {
      amount: parseFloat(node.price.amount as string),
      currencyCode: node.price.currencyCode as string,
    },
    selectedOptions: node.selectedOptions?.map((o) => ({
      name: o.name,
      value: o.value,
    })),
  }));

export type Product = ReturnType<typeof transformProductQuery>;
export type ProductVariant = ReturnType<typeof transformProductVariantQuery>[0];
