<template>
  <ConfigurationFloatingHeader :visible="showFloatingHeader" />
  <div ref="el" class="v-configurator" :id="elementId">
    <div class="container">
      <div class="v-configurator-wrapper">
        <div class="v-configurator-left">
          <div class="v-configurator-content-wrapper mobile">
            <div class="container">
              <slot :cheapest-price="cheapestPrice"></slot>
            </div>
          </div>
          <div class="v-configurator-left-wrapper">
            <ProductImages />
          </div>
        </div>
        <div class="v-configurator-right">
          <div class="v-configurator-content">
            <div class="v-configurator-content-wrapper desktop">
              <slot :cheapest-price="cheapestPrice"></slot>
            </div>
            <ConfiguratorSections :product-id="productId" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ConfigurationFloatingHeader from '@/js/vue/components/Configurator/ConfigurationFloatingHeader.vue';
import ConfiguratorSections from '@/js/vue/components/Configurator/ConfiguratorSections.vue';
import ProductImages from '@/js/vue/components/Configurator/ProductImages.vue';
import { useConfiguratorStore } from '@/js/vue/shopify/stores/configurator';
import { computed, nextTick, onMounted, PropType, ref } from 'vue';
import { VARIANT_NAMES } from '@/js/vue/shopify/keys';
import { logEvent } from '@/js/plugins/analytics';

const configurator = useConfiguratorStore();
const el = ref<HTMLElement>();

const props = defineProps({
  productId: {
    type: [String, Number],
    required: true,
  },
  productAusfuehrungWhitelist: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  elementId: {
    type: String,
    default: () => 'tisch-konfigurieren',
  },
});

const cheapestPrice = computed(() => {
  return configurator.cheapestPrice;
});

const showFloatingHeader = ref(false);
const onScrollHandler = () => {
  const rect = el.value?.getBoundingClientRect();

  if (!rect) {
    return;
  }

  if (rect.y < 0 && rect.height + rect.y > 0) {
    showFloatingHeader.value = true;
  } else {
    showFloatingHeader.value = false;
  }
};

onMounted(() => {
  window.addEventListener('scroll', onScrollHandler, {
    passive: true,
  });

  configurator.products.main.loadProduct(props.productId, props.productAusfuehrungWhitelist).then(() => {
    const params = new URLSearchParams(location.href.split('?').at(1));

    if (params.get('ausfuehrung')) {
      configurator.selectedProductOptions.main[VARIANT_NAMES.Ausfuehrung] = params.get('ausfuehrung');
    }

    logEvent('configuratorLoaded', {
      productId: configurator.products.main.product.id,
      cheapestPrice: configurator.cheapestPriceAmount,
      totalPrice: configurator.totalPrice,
    });
  });

  if (window.location.hash === `#${props.elementId}`) {
    nextTick(() => {
      el.value?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
  }
});
</script>

<style lang="scss" scoped>
.v-configurator {
  background: $wbeige;
  position: relative;

  .v-configurator-wrapper {
    display: flex;
    align-items: flex-start;
    @include media(L) {
      align-items: stretch;
      flex-direction: column;
    }
  }

  .v-configurator-left {
    width: 37%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    @include media(XL1200) {
      width: 42%;
    }
    @include media(L) {
      width: 100vw;
      position: relative;
      margin-left: 50%;
      transform: translate(-50%, 0);
    }

    .v-configurator-content-wrapper {
      background: $wbrown;
      color: $white;
      padding-bottom: 80px;
      @include media(S) {
        padding-bottom: 60px;
      }
    }
    .v-configurator-left-wrapper {
      position: sticky;
      top: 96px;
      min-height: calc(100vh - 96px);
      display: flex;
      padding: 10vh 0;
      width: 100%;
      @include media(XL1200) {
        top: 92px;
        min-height: calc(100vh - 92px);
      }
      @include media(L) {
        position: relative;
        top: 0;
        padding: 5vh 80px 5vh 0;
        min-height: 60vh;
      }
      @include media(M) {
        padding-right: 50px;
      }
      @include media(S) {
        padding: 30px 30px 30px 0;
      }
    }
  }

  .v-configurator-right {
    width: 60%;
    margin-left: 40%;
    color: $white;
    @include media(XL1200) {
      margin-left: 45%;
      width: 55%;
    }
    @include media(L) {
      margin-left: 0%;
      width: 100%;
    }

    .v-configurator-content {
      margin-right: -9999px;
      padding: 120px 9999px 120px 120px;
      background: $wbrown;
      @include media(XL) {
        padding: 100px 9999px 100px 100px;
      }
      @include media(XL1200) {
        padding: 80px 9999px 80px 50px;
      }
      @include media(L) {
        margin: 0 -999px;
        padding: 60px 999px;
      }
      @include media(M) {
        padding-top: 40px;
      }
    }
  }
}
</style>

<style lang="scss">
.v-configurator-content-wrapper {
  h2 {
    font-weight: 300;
    margin: 0;
    font-size: 38px;
    line-height: 44px;
    @include media(XL) {
      font-size: 32px;
      line-height: 40px;
    }
    @include media(XL1200) {
      font-size: 28px;
      line-height: 36px;
    }
    @include media(M) {
      font-size: 25px;
      line-height: 33px;
    }
  }
  h4 {
    margin: 20px 0 0;
  }
  h6 {
    text-align: left;
    margin: 40px 0 0;
  }
  ul {
    margin: 30px 0 0;
    
    li {
      position: relative;
      padding-left: 60px;
      font-weight: 500;
      margin-bottom: 23px;
      font-size: 20px;
      line-height: 1.22;
      @include media(XL1200) {
        font-size: 18px;
        margin-bottom: 20px
      }
      @include media(L) {
        padding-left: 50px;
      }
      @include media(M) {
        font-size: 16px;
        padding-left: 35px;
        margin-bottom: 15px;
      }

      &:before {
        content: '';
        background-image: url(asset('icons/icon-tick-white.svg'));
        background-size: contain;
        position: absolute;
        left: 0;
        width: 31px;
        height: 27px;
        @include media(M) {
          width: 23px;
          height: 20px;
          top: -2px;
        }
      }
    }
  }
}
</style>